<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol>
						<div class="user-info">
							<CRow class="form-group">
								<CCol class="user-field col-md-3 col-lg-2 col-12">
									{{ $t("Profile.Status") }}
								</CCol>
								<CCol class="user-value text-muted pt-2 pt-md-0">
									{{ labelStatus(currentUser.status) }}
								</CCol>
							</CRow>
							<CRow class="form-group">
								<CCol class="user-field col-md-3 col-lg-2 col-12">
									{{ $t("Profile.Name") }}
								</CCol>
								<CCol class="user-value text-muted pt-2 pt-md-0">
									{{ currentUser.fullName }}
								</CCol>
							</CRow>
							<CRow class="form-group">
								<CCol class="user-field col-md-3 col-lg-2 col-12">
									{{ $t("Profile.Email") }}
								</CCol>
								<CCol class="user-value text-muted pt-2 pt-md-0">
									{{ currentUser.email }}
								</CCol>
							</CRow>
							<CRow class="form-group">
								<CCol class="user-field col-md-3 col-lg-2 col-12">
									{{ $t("Profile.ManagePK") }}
								</CCol>
								<CCol class="user-value text-muted pt-2 pt-md-0">
									{{ getClinicName(currentUser.clinic) }}
								</CCol>
							</CRow>
							<CRow class="form-group">
								<CCol class="user-field col-md-3 col-lg-2 col-12">
									{{ $t("Profile.Role") }}
								</CCol>
								<CCol class="user-value text-muted pt-2 pt-md-0">
									{{ labelRole(currentUser.role) }}
								</CCol>
							</CRow>
						</div>
						<div class="user-edit">
							<CButton color="outline-custom" @click="toggleUpdateModal(true)">
								{{ $t("Profile.Edit") }}
							</CButton>
						</div>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>

		<UpdateProfile
			v-if="showUpdateProfile"
			:current-user="currentUser"
			@hide="toggleUpdateModal(false)"
		/>
	</div>
</template>

<script>
import { GET_USER_LOGIN_PROFILE } from "../store/action-types"
import { CHANGE_STATUS_UPDATE_PROFILE } from "../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapMutations, mapState } = createNamespacedHelpers("authentication")
import { ADMIN_STATUS, USER_ROLE } from "../../../shared/plugins/constants"

export default {
	name: "UsersProfile",

	components: {
		UpdateProfile: () => import("../components/UpdateProfile"),
	},

	data() {
		return {}
	},

	computed: {
		...mapState(["currentUser", "showUpdateProfile"]),
	},

	created() {
		this.getUserProfile()
	},

	methods: {
		...mapActions({ GET_USER_LOGIN_PROFILE }),
		...mapMutations({ CHANGE_STATUS_UPDATE_PROFILE }),

		getUserProfile: async function () {
			await this.GET_USER_LOGIN_PROFILE()
		},

		toggleUpdateModal(status) {
			this.CHANGE_STATUS_UPDATE_PROFILE(status)
		},

		getClinicName(clinic) {
			if (this.currentUser.role === USER_ROLE.SYSTEM_ADMIN) return this.$t("AdminForm.All")
			else return clinic?.name
		},

		labelStatus(value) {
			return {
				[ADMIN_STATUS.ACTIVE]: this.$t("Status.Active"),
				[ADMIN_STATUS.BANNED]: this.$t("Status.Inactive"),
			}[value]
		},

		labelRole(value) {
			return {
				[USER_ROLE.SYSTEM_ADMIN]: this.$t("AdminForm.SystemAdmin"),
				[USER_ROLE.SYSTEM_CLINIC]: this.$t("AdminForm.ClinicAdmin"),
				[USER_ROLE.SUPPORT_CLINIC]: this.$t("AdminForm.ClinicSupport"),
			}[value]
		},
	},
}
</script>
